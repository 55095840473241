<template>
  <form @submit.prevent="submit" v-if="hasForm">
    <div class="font-medium mb-5 text-lg" v-if="form.title">
      {{ form.title }}
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-10">
      <template v-for="(field, index) in form.data">
        <div class="col-span-1" :key="index">
          <form-group
            :form="form"
            :name="processPropValue(field.name)"
            :type="processPropValue(field.type)"
            :options="processPropValue(field.options)"
            :class="processPropValue(field.class)"
            v-model="field.value"
            novalidate
            class="mb-0"
          >
            {{ field.label }}
          </form-group>
        </div>
      </template>
    </div>

    <div class="alert alert-red items-start mb-10" v-if="getFormError(form)">
      <div class="alert-icon">
        <ion-icon name="alert"></ion-icon>
      </div>
      <div v-html="getFormError(form)"></div>
    </div>

    <button type="submit" class="btn btn-blue" :disabled="form.loading">
      <sm-loader v-if="form.loading" class="sm-loader-white" />
      <span v-else>Submit</span>
    </button>
  </form>
</template>

<script>
  export default {
    computed: {
      hasForm() {
        return !!this.form;
      }
    },
    methods: {
      submit() {},
      processPropValue(property) {
        switch (property?.constructor) {
          case Function:
            return property.apply(null, [this.form]);
          default:
            return property;
        }
      }
    }
  }
</script>
