<script>
  import Form from '@/components/forms/Form.vue';

  export default {
    extends: Form,
    props: {
      selectedUser: {
        type: Object,
        default: () => null
      }
    },
    data() {
      return {
        form: this.$options.basicForm([
          {
            name: 'column',
            label: 'Find by...',
            type: 'select',
            options: [
              { title: 'Email', value: 'email'},
              { title: 'Phone', value: 'phone_no'},
              { title: 'BVN', value: 'bvn'},
              { title: 'ID', value: 'id'},
            ]
          },
          {
            name: 'query',
            rules: 'required',
            label: 'Find using...'
          }
        ], {
          title: 'Find user using the form below.'
        })
      }
    },
    mounted () {
      this.getUserFromQuery();
    },
    methods: {
      getUserFromQuery() {
        const userId = this.$route.query.userId;
        if (!userId) {
          return false;
        }

        this.form.data.column.value = 'id';
        this.form.data.query.value = userId;
        this.submit();
      },
      async submit() {
        if (!this.validateForm(this.form)) {
          return false;
        }

        this.form.error = null;
        this.form.setLoading();
        await this.sendRequest('admin.users.search', {
          query: this.getFormValue('query'),
          column: this.getFormValue('column')
        },
        {
          success: (response) => {
            this.$emit('loaded', response.data.user);
          },
          error: (error) => {
            this.form.error = error;
            if (error.response?.status === 404) {
              this.form.error = new Error('Unable to find User');
            }
          }
        });
        this.form.setLoading(false);
      }
    }
  }
</script>
